(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name lib.controller:LibCtrl
   *
   * @description
   *
   */
  angular
    .module('lib')
    .controller('LibCtrl', LibCtrl);

  function LibCtrl() {
    var vm = this;
    vm.ctrlName = 'LibCtrl';
  }
}());
